import axios from "axios";

const envObj = {
  development: "http://localhost:3000",
  staging: "https://www.ratsap.com.br",
  production: "https://www.erural.net",
};

const api = axios.create({
  withCredentials: true,
  baseURL: envObj[import.meta.env.VITE_RAILS_ENV],
  headers: {
    "X-CSRF-Token": document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content"),
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export default api;
